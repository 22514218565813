import { styled, Text, spacing, mq } from '@thg-commerce/enterprise-theme'
import { HorizontalAlignment } from '@thg-commerce/enterprise-core'

import { Button, SafeHtml } from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'

export const Container = styled.div`
  width: 100%;
`

export const Title = styled.p<{
  horizontalAlignment?: HorizontalAlignment
}>`
  text-align: ${(props) => props.horizontalAlignment || 'center'};
  margin-bottom: ${spacing(2)};

  ${(props) =>
    Text(
      props.theme.widget.aboutUsVideo.title.font.entry,
      'alternate',
      props.theme.widget.aboutUsVideo.title.font.hasScaledText,
    )};
`

export const Content = styled(SafeHtml)<{
  horizontalAlignment?: HorizontalAlignment
}>`
  ${Text('bodyText', 'default')};
  text-align: ${(props) => props.horizontalAlignment || 'center'};
  margin-bottom: ${spacing(4)};
`

export const StyledGrid = styled(Grid)`
  align-items: center;
  margin: 0;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(4)};
  }
`

export const VideoGridItem = styled(GridItem)`
  margin: 0;
`

export const TextGridItem = styled(GridItem)<{
  horizontalAlignment?: HorizontalAlignment
}>`
  text-align: center;
  margin: 0;
  padding: ${spacing(4)} ${spacing(2)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(4)};
  }
`

export const CTAWrapper = styled.div<{
  horizontalAlignment?: HorizontalAlignment
}>`
  display: flex;
  justify-content: ${(props) =>
    props.horizontalAlignment === 'left'
      ? 'flex-start'
      : props.horizontalAlignment === 'right'
      ? 'flex-end'
      : 'center'};
`
export const StyledButton = styled(Button)`
  &:not(:last-child) {
    margin-right: ${spacing(8)};
  }
`
