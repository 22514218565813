import * as React from 'react'

import {
  GlobalAboutUsVideo,
  RichContent,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  AboutUsVideo,
  AboutUsVideoProps,
} from '@thg-commerce/enterprise-widget-about-us-video'
import { styled, mq } from '@thg-commerce/gravity-theme'

import { BaseWidgetProps } from '../types'

export type AboutUsVideoRendererProps = BaseWidgetProps &
  Omit<GlobalAboutUsVideo, 'query' | 'textBlock'> & {
    globalAboutUsVideoTextBlock: RichContent
  }

const StyledAboutUsVideo = styled(AboutUsVideo)`
  margin: 0 auto;
  max-width: ${(props) => props.theme.site.siteWidth};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    max-width: 720px;
  }
`

export const AboutUsVideoRenderer = (props: AboutUsVideoRendererProps) => {
  if (props.videoURL) {
    const AboutUsVideoRendererProps: AboutUsVideoProps = {
      title: props.title || '',
      textBlock: props.globalAboutUsVideoTextBlock.content[0].content || '',
      videoUrl: props.videoURL,
      buttonLink: props.buttonLink || '',
      buttonText: props.buttonText || '',
      transcript: props.transcript?.content[0].content || '',
      videoTitle: props.videoTitle || '',
      horizontalAlignment: 'center',
    }
    return <StyledAboutUsVideo {...AboutUsVideoRendererProps} />
  }

  return null
}
