import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { SocialEmbed } from '@thg-commerce/enterprise-widget-social-embed'

import {
  Container,
  Title,
  Content,
  CTAWrapper,
  StyledButton,
  StyledGrid,
  VideoGridItem,
  TextGridItem,
} from './styles'

export interface AboutUsVideoProps {
  title?: string
  textBlock?: string
  buttonText?: string
  buttonLink?: string
  videoUrl?: string
  videoTitle?: string
  transcript?: string
  horizontalAlignment?: HorizontalAlignment
  className?: string
}

export const AboutUsVideo = (props: AboutUsVideoProps) => {
  return (
    <Container className={props.className}>
      <StyledGrid columns={12} rows={1}>
        <VideoGridItem rowStart={1} colSpan={12} rowSpan={1}>
          {props.videoUrl && (
            <SocialEmbed
              provider="youtube"
              url={props.videoUrl}
              isAmp={false}
              data-testid="social-embed"
            />
          )}
        </VideoGridItem>
        <TextGridItem colSpan={12}>
          {props.title && (
            <Title
              horizontalAlignment={props.horizontalAlignment}
              data-testid="about-us-title"
            >
              {props.title}
            </Title>
          )}
          {props.textBlock && (
            <Content
              content={props.textBlock}
              horizontalAlignment={props.horizontalAlignment}
            />
          )}
          {props.buttonText && props.buttonLink && (
            <CTAWrapper horizontalAlignment={props.horizontalAlignment}>
              <StyledButton
                renderedAs="a"
                href={props.buttonLink}
                data-testid="about-us-cta"
              >
                {props.buttonText}
              </StyledButton>
            </CTAWrapper>
          )}
        </TextGridItem>
      </StyledGrid>
    </Container>
  )
}
